import { classNames } from "../utils/etc";

/**
 * size: "bold" | "fill" | "light" | "thin" | "duotone" or undefined (default, refers regular)
 */
export function PhosphorIcon(props: {
  name: string;
  class?: string;
  size?: "bold" | "fill" | "light" | "thin" | "duotone";
  fontSize?: number;
  onClick?: () => void;
  label?: string;
}) {
  return (
    <i
      aria-label={props.label}
      class={classNames(
        props.size ? `ph-${props.size}` : "ph",
        `ph-${props.name}`,
        props.class
      )}
      style={{
        "font-size": (props.fontSize ?? 20) + "px",
      }}
      onClick={props.onClick}
    />
  );
}
