import { Env } from "~/types";

export var env = import.meta.env.VITE_ENV;

const validEnvs = ["Prod", "Preprod", "Staging", "Local"] as const;

if (process.env.LOCAL === "1") {
  env = "Local";
}

if (!env) {
  env = "Staging";
}

if (!validEnvs.includes(env as Env)) {
  throw new Error(`Invalid env: ${env}`);
}

type Status = "enabled" | "disabled";

type paymentGateway = "razorpay" | "custom";

const ENABLE_MIDDLEWARE_LOGS: Status =
  process.env.ENABLE_MIDDLEWARE_LOGS === "1" ? "enabled" : "disabled";

export type Config = {
  env: Env;
  baseUrl: string;
  baseDomainUrl: string;
  receiptUrl: string;
  enableErrorLoggingToSentry: boolean;
  razorpayKey: string;
  isRazorpayEnabled: boolean;
  bannerPath: string;
  showErrorMessageInErrorView: boolean;
  clientBannerBasePath: string;
  clientBannerFileName: string;
  paths: {
    homePageUrl: string;
    categoryPageBasePath: string;
  };
  consoleLogging: {
    router: Status;
    fraudChecks: Status;
    serverRequests: Status;
    sdk: Status;
    middleware: Status;
    events: Status;
  };
  uiLogging: {
    loaderFromContext: Status;
  };
  clientIdPrefix: Record<string, string>;
  mixpanelEvents: {
    enabledForWebStore: boolean;
    enabledForSdk: boolean;
    enabledForRnr: boolean;
  };
  axisClientId: string;
  customizedSDKClients: {
    limitedCategoryContent: string[];
    disableHomePageBackButton: string[];
    consumeCoinsByDefault: string[];
    squareAssetsForPopularBrands: string[];
    lowercaseTitleText: string[];
  };
  donationPartner: string[];
  payUUrl: string;
  sesssionDurationInMinutes: {
    webstore: number;
    rnr: number;
    squid: number;
    gift_receiver: number;
    axis_portal: number;
    pluto: number;
    smart_greeting: number; // sender's end
  };
  webstore: {
    paymentGateway: paymentGateway;
  };
};

const defaultConfig = {
  consoleLogging: {
    router: "disabled" as Status,
    fraudChecks: "disabled" as Status,
    serverRequests: "disabled" as Status,
    sdk: "disabled" as Status,
    middleware: ENABLE_MIDDLEWARE_LOGS,
    events: "disabled" as Status,
  },
  uiLogging: {
    loaderFromContext: "disabled" as Status,
  },
  paths: {
    homePageUrl: "/",
    categoryPageBasePath: "/shop-by-category/",
  },
  clientIdPrefix: {
    onepercent: "onepercent",
    visitHealth: "visit-health",
    gapup: "gapup",
    raisoni: "raisoni",
  },
  clientBannerBasePath: "partners",
  clientBannerFileName: "home_banners.json",
  isRazorpayEnabled: false,
  mixpanelEvents: {
    enabledForWebStore: false,
    enabledForSdk: false,
    enabledForRnr: false,
  },
  donationPartner: ["ketto"],
  sesssionDurationInMinutes: {
    webstore: 60 * 24 * 30, // 1 month
    rnr: 60 * 24, // 1 day
    axis_portal: 60 * 24 * 7, // 7 days
    squid: 30,
    gift_receiver: 30, // all gift receiver's end
    pluto: 60 * 24, // 1 day // sender's end
    smart_greeting: 30, // sender's end
  },
  webstore: {
    paymentGateway: "custom" as paymentGateway,
  },
};

const configs: {
  Prod: Config;
  Preprod: Config;
  Staging: Config;
  Local: Config;
} = {
  Prod: {
    ...defaultConfig,
    env: "Prod",
    baseUrl: "https://api.myhubble.money",
    baseDomainUrl: "https://www.myhubble.money",
    receiptUrl: "https://receipts.myhubble.money",
    enableErrorLoggingToSentry: true,
    razorpayKey: "rzp_live_VjACe6SdHqHjuK",
    bannerPath: "home_page/banners_web.json",
    showErrorMessageInErrorView: false,
    mixpanelEvents: {
      enabledForWebStore: true,
      enabledForSdk: false,
      enabledForRnr: true,
    },
    axisClientId: "axisbank-llE1XBk0",
    customizedSDKClients: {
      limitedCategoryContent: ["axisbank-llE1XBk0"],
      disableHomePageBackButton: [
        "visit-health-4AefioZfPjvOd51A",
        "gapup-gDM2WoUq",
      ],
      consumeCoinsByDefault: ["gapup-gDM2WoUq"],
      squareAssetsForPopularBrands: [""],
      lowercaseTitleText: [""],
    },
    payUUrl: "https://secure.payu.in/_payment",
  },
  Preprod: {
    ...defaultConfig,
    env: "Preprod",
    baseDomainUrl: "https://www.myhubble.money",
    baseUrl: "https://api.myhubble.money",
    receiptUrl: "https://receipts.dev.myhubble.money",
    enableErrorLoggingToSentry: true,
    razorpayKey: "rzp_live_VjACe6SdHqHjuK",
    bannerPath: "home_page/banners_web.json",
    showErrorMessageInErrorView: false,
    consoleLogging: {
      router: "enabled",
      fraudChecks: "enabled",
      serverRequests: "disabled",
      sdk: "disabled",
      middleware: ENABLE_MIDDLEWARE_LOGS,
      events: "disabled",
    },
    axisClientId: "axisbank-llE1XBk0",
    customizedSDKClients: {
      limitedCategoryContent: ["axisbank-llE1XBk0"],
      disableHomePageBackButton: [
        "visit-health-4AefioZfPjvOd51A",
        "gapup-gDM2WoUq",
      ],
      consumeCoinsByDefault: ["gapup-gDM2WoUq"],
      squareAssetsForPopularBrands: ["lazypay-ADKwqaBA"],
      lowercaseTitleText: ["lazypay-ADKwqaBA"],
    },
    payUUrl: "https://secure.payu.in/_payment",
  },
  Staging: {
    ...defaultConfig,
    env: "Staging",
    baseUrl: "https://api.dev.myhubble.money",
    baseDomainUrl: "https://vouchers.dev.myhubble.money",
    receiptUrl: "https://receipts.dev.myhubble.money",
    enableErrorLoggingToSentry: false,
    razorpayKey: "rzp_test_UaXfewLGPZYo8Y",
    bannerPath: "home_page/banners_web.staging.json",
    showErrorMessageInErrorView: true,
    consoleLogging: {
      router: "enabled",
      fraudChecks: "enabled",
      serverRequests: "disabled",
      sdk: "disabled",
      middleware: ENABLE_MIDDLEWARE_LOGS,
      events: "enabled",
    },
    axisClientId: "acme-code-3241c2c1",
    customizedSDKClients: {
      limitedCategoryContent: ["acme-code-3241c2c1"],
      disableHomePageBackButton: ["visit-health", "gapup-83yLRNIp"],
      consumeCoinsByDefault: ["gapup-83yLRNIp"],
      squareAssetsForPopularBrands: ["lazypay-ADKwqaBA"],
      lowercaseTitleText: ["lazypay-ADKwqaBA"],
    },
    payUUrl: "https://test.payu.in/_payment",
    webstore: {
      paymentGateway: "razorpay" as paymentGateway,
    },
  },
  Local: {
    ...defaultConfig,
    env: "Local",
    baseUrl: "https://api.dev.myhubble.money",
    baseDomainUrl: "https://vouchers.dev.myhubble.money",
    receiptUrl: "https://receipts.dev.myhubble.money",
    enableErrorLoggingToSentry: false,
    razorpayKey: "rzp_test_UaXfewLGPZYo8Y",
    bannerPath: "home_page/banners_web.staging.json",
    showErrorMessageInErrorView: true,
    consoleLogging: {
      router: "enabled",
      fraudChecks: "enabled",
      serverRequests: "disabled",
      sdk: "enabled",
      middleware: ENABLE_MIDDLEWARE_LOGS,
      events: "enabled",
    },
    uiLogging: {
      loaderFromContext: "enabled",
    },
    axisClientId: "acme-code-3241c2c1",
    customizedSDKClients: {
      limitedCategoryContent: ["acme-code-3241c2c1"],
      disableHomePageBackButton: ["visit-health", "gapup-83yLRNIp"],
      consumeCoinsByDefault: ["gapup-83yLRNIp"],
      squareAssetsForPopularBrands: ["lazypay-ADKwqaBA"],
      lowercaseTitleText: ["lazypay-ADKwqaBA"],
    },
    payUUrl: "https://test.payu.in/_payment", //
  },
};

export const config = configs[env as Env];

export const HUBBLE_CLIENT_ID = "hubble";

export const clientLogoDescriptions = {
  "visit-health": "Fitcoin Store",
};

export const CSS_FILES = {
  default: "/styles/main.css",
  "lazypay-ADKwqaBA": "/styles/lazypay.css",
  "mock-niyo": "/styles/mobikwik.css",
};

export const FONTS = {
  default: [
    "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Krona+One&display=swap",
  ],
  rnr: ["https://fonts.googleapis.com/css2?family=Sacramento&display=swap"],
  sdk: [
    {
      "mock-niyo":
        "https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap",
    },
  ],
};

export const RNR_PAGE_TITLE = "Gifts & Rewards - By Hubble";
